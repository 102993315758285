"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EasyEditWrapper = exports.RightCol = exports.LeftCol = exports.SingleCol = exports.DoubleColumnLayout = exports.SingleColumnLayout = exports.InlineEditField = exports.ActivityColumn = exports.Activity = exports.Half = exports.Section = exports.SubduedLabel = exports.Description = exports.Summary = exports.ActionTitle = exports.DetailsColumn = exports.Column = exports.Content = exports.AnchorTag = exports.LinkTitle = exports.Title = exports.ModalContent = exports.Modal = void 0;

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _blocks = require("@bueno/blocks");

var _reactRouterDom = require("react-router-dom");

const Modal = (0, _styledComponents.default)(_blocks.Modal)`
  .Modal-content > section {
    width: ${props => props.width !== undefined ? `${props.width}px` : '860px'};
    max-width: ${props => props.width !== undefined ? `${props.width}px` : '860px'};
    min-height: 500px;

    @media (min-height: 750px) {
      min-height: 600px;
    }
  }
`;
exports.Modal = Modal;
const ModalContent = (0, _styledComponents.default)(_blocks.ModalContent)`
  position: relative;
  overflow: ${({
  isPending
}) => isPending ? 'hidden' : 'auto'};
  z-index: ${_blocks.layer.modal};
`;
exports.ModalContent = ModalContent;
const Title = (0, _styledComponents.default)(_blocks.DisplaySmall)`
  text-align: center;
  font-weight: bold;
  color: ${_blocks.color.theme.typography.ink};
`;
exports.Title = Title;
const LinkTitle = (0, _styledComponents.default)(_reactRouterDom.Link)`
  display: inline-flex;
  align-items: center;
  color: ${_blocks.color.theme.typography.ink};
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
  svg {
    fill: ${_blocks.color.theme.icon.default};
    margin-left: ${_blocks.spacing.extraTight};
    stroke-width: 2px;
  }
`;
exports.LinkTitle = LinkTitle;
const AnchorTag = _styledComponents.default.a`
  text-decoration: none;
  color: ${_blocks.palette.blue8};
  svg {
    fill: ${_blocks.palette.blue8};
    margin-left: ${_blocks.spacing.extraTight};
    stroke-width: 2px;
    vertical-align: middle;
  }
  &:hover {
    text-decoration: underline;
  }
`;
exports.AnchorTag = AnchorTag;
const Content = _styledComponents.default.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;
exports.Content = Content;
const Column = _styledComponents.default.div`
  flex-basis: 50%;
  max-width: 50%;
  height: 500px;
  overflow: auto;

  @media (min-height: 750px) {
    height: 672px;
  }
`; // * VIEW ACTIONS STYLES

exports.Column = Column;
const DetailsColumn = (0, _styledComponents.default)(Column)``;
exports.DetailsColumn = DetailsColumn;
const ActionTitle = (0, _styledComponents.default)(_blocks.BodyLarge)`
  font-weight: ${_blocks.fontWeight.bold};
`;
exports.ActionTitle = ActionTitle;
const Summary = (0, _styledComponents.default)(_blocks.DisplaySmall)`
  margin-top: ${_blocks.spacing.default};
  overflow-wrap: break-word;
`;
exports.Summary = Summary;
const Description = _styledComponents.default.div`
  margin-top: ${_blocks.spacing.default};
  /* negative margin against the margin bottom that created by
  p tag inside MarkDown element */
  margin-bottom: -${_blocks.spacing.tight};
`;
exports.Description = Description;
const SubduedLabel = (0, _styledComponents.default)(_blocks.BodySmall)`
  color: ${_blocks.color.theme.typography.subdued};
  margin-bottom: ${_blocks.spacing.extraTight};
`;
exports.SubduedLabel = SubduedLabel;
const Section = _styledComponents.default.div`
  padding: ${props => props.padding ? props.padding : _blocks.spacing.loose};
  border-bottom: 1px solid ${_blocks.palette.neutral3};
`;
exports.Section = Section;
const Half = _styledComponents.default.div`
  width: 50%;
`;
exports.Half = Half;
const Activity = (0, _styledComponents.default)(_blocks.Body)`
  color: ${_blocks.color.theme.typography.subdued};
  margin-top: ${_blocks.spacing.extraTight};
`;
exports.Activity = Activity;
const ActivityColumn = (0, _styledComponents.default)(Column)`
  border-left: 1px solid ${_blocks.palette.neutral3};
  background: ${_blocks.palette.neutral1};
  overflow: hidden;
`;
exports.ActivityColumn = ActivityColumn;
const InlineEditField = _styledComponents.default.div`
  padding-bottom: ${_blocks.spacing.default};
`; // * CREATE/ASSOCIATE STYLES

exports.InlineEditField = InlineEditField;
const SingleColumnLayout = _styledComponents.default.section`
  width: 100%;
`;
exports.SingleColumnLayout = SingleColumnLayout;
const DoubleColumnLayout = _styledComponents.default.section`
  display: grid;
  grid-template-columns: 287px 571px;
  grid-column-gap: 1px;

  @media (min-height: 800px) {
    grid-template-columns: 287px 571px;
  }
`;
exports.DoubleColumnLayout = DoubleColumnLayout;
const SingleCol = _styledComponents.default.div`
  height: 455px;
  overflow: auto;

  @media (min-height: 800px) {
    height: 654px;
  }
`;
exports.SingleCol = SingleCol;
const LeftCol = _styledComponents.default.div`
  background-color: ${_blocks.palette.neutral1};
  border-radius: 0px 0px 0px 8px;
  border-right: 1px solid ${_blocks.palette.neutral3};
  /* this height is manually calculated from figma design */
  height: 455px;
  overflow: hidden;

  @media (min-height: 800px) {
    height: 654px;
  }
`;
exports.LeftCol = LeftCol;
const RightCol = (0, _styledComponents.default)(SingleCol)``;
exports.RightCol = RightCol;
const EasyEditWrapper = _styledComponents.default.div`
  /* Target inline text edit field to indicate this is editable */
  .easy-edit-wrapper:hover {
    background-color: ${_blocks.palette.neutral1};
    box-shadow: ${_blocks.palette.neutral1} 0 0 0 4px;
  }

  /**
   * NOTE: The code below is to overwrite the Easy Edit placeholder style
   */
  .ActionDetail--Description__no-value,
  .ActionDetail--ReportLink__no-value {
    .easy-edit-wrapper {
      color: ${_blocks.color.theme.semantic.default.secondary.foreground};
      position: relative;
      display: inline-flex;
      height: ${_blocks.controls.height.wafer};
      background-color: transparent;
      border-color: transparent;
      border-radius: ${_blocks.borderRadius.small};
      font-size: 12px;
      line-height: 1;
      font-weight: ${_blocks.fontWeight.bold};
      text-decoration: none;
      text-transform: none;
      align-items: center;

      transition: color ${_blocks.animation.timing.fast} linear,
        border ${_blocks.animation.timing.fast} linear,
        background-color ${_blocks.animation.timing.fast} linear;
    }
  }

  .ActionDetail--Description__no-value .easy-edit-wrapper {
    border: 1px solid transparent;
    padding: 0 ${_blocks.spacing.tight};

    &:hover {
      border-color: currentColor;
    }
  }
`;
exports.EasyEditWrapper = EasyEditWrapper;