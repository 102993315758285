"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchNotificationSettings = fetchNotificationSettings;
exports.fetchUpdateNotificationSettings = fetchUpdateNotificationSettings;

var _axios = _interopRequireDefault(require("axios"));

async function fetchNotificationSettings(userId) {
  return (await _axios.default.get(`/v2/users/${userId}/notifications`)).data;
}

async function fetchUpdateNotificationSettings(userId, data) {
  return (await _axios.default.put(`/v2/users/${userId}/notifications`, data)).data;
}