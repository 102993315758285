"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getApertureLink = getApertureLink;

var _queryStringForAll = _interopRequireDefault(require("query-string-for-all"));

const apertureUrl = window.APERTURE_URL || 'http://localhost:8000';

function getApertureLink({
  organisationId,
  siteId,
  equipId,
  ruleId,
  occurrenceId,
  range: [from, to],
  locale
}) {
  const query = _queryStringForAll.default.stringify({
    organisationId,
    siteId,
    equipId,
    ruleId,
    occurrenceId,
    from: from.toISOString(),
    to: to.toISOString()
  });

  return `${apertureUrl}/${locale}/inbound/bonfire/insight?${query}`;
}