"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stripProjectPrefix = stripProjectPrefix;
exports.getReadableDates = getReadableDates;
exports.mapToJiraProps = mapToJiraProps;

var _dateFns = require("date-fns");

var _locale = require("date-fns/locale");

function stripProjectPrefix(actionId = '-') {
  return actionId.split('-')[1];
}

function getReadableDates(created, updated, locale) {
  return locale === 'en' ? {
    created: `Created ${(0, _dateFns.format)(new Date(created), 'MMM d, yyyy', {
      locale: _locale.enGB
    })}`,
    updated: updated !== undefined ? `• Updated ${(0, _dateFns.formatDistanceStrict)(new Date(updated), new Date(), {
      locale: _locale.enGB,
      addSuffix: true
    })}` : ''
  } : {
    created: `Créé ${(0, _dateFns.format)(new Date(created), 'MMM d, yyyy', {
      locale: _locale.fr
    })}`,
    updated: updated !== undefined ? `• Mise à jour ${(0, _dateFns.formatDistanceStrict)(new Date(updated), new Date(), {
      locale: _locale.fr,
      addSuffix: true
    })}` : ''
  };
}

function mapToJiraProps(editable) {
  const updated = { ...editable,
    bonfire_status: editable.status,
    transition_reason: editable.statusChangeReason,
    assignee_name: editable.assignee,
    bonfire_assignee: editable.assignee,
    assignee_display_name: editable.assigneeDisplay,
    elec: editable.electricity,
    total_financial_impact: editable.totalFinancialImpact,
    operational_savings: editable.operationalSavings,
    screenshot_link: editable.screenshotUrl,
    skyspark_link: editable.reportUrl
  };
  const final = {};
  Object.keys(updated).forEach(key => {
    if (updated[key] !== undefined) {
      final[key] = updated[key];
    }
  });
  return final;
}