"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SettingDescription = exports.SettingLabel = exports.SettingGroup = exports.SettingHeader = exports.ContentRow = exports.Content = exports.Frame = exports.PageTitle = void 0;

var _blocks = require("@bueno/blocks");

var _styledComponents = _interopRequireDefault(require("styled-components"));

/* eslint-disable @typescript-eslint/prefer-ts-expect-error */

/* eslint-disable no-eq-null */
const PageTitle = (0, _styledComponents.default)(_blocks.DisplayMedium)`
  margin-bottom: ${_blocks.spacing.loose};
  text-align: center;
`;
exports.PageTitle = PageTitle;
const Frame = (0, _styledComponents.default)(_blocks.Frame)`
  overflow: visible;
`;
exports.Frame = Frame;
const Content = _styledComponents.default.div`
  display: grid;
  gap: ${_blocks.spacing.ultraLoose};
  margin: ${_blocks.spacing.extraLoose};
`;
exports.Content = Content;
const ContentRow = _styledComponents.default.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-bottom: 1px solid ${_blocks.color.theme.divider.solid};
  padding-bottom: ${_blocks.spacing.ultraLoose};

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;
exports.ContentRow = ContentRow;
const SettingHeader = _styledComponents.default.div`
  ${_blocks.typography.displaySmall};
  font-weight: ${_blocks.fontWeight.bold};
`;
exports.SettingHeader = SettingHeader;
const SettingGroup = _styledComponents.default.div`
  display: grid;
  align-items: center;
  grid-template-columns: max-content auto;
  grid-column-gap: ${_blocks.spacing.extraLoose};
  grid-row-gap: ${_blocks.spacing.loose};
`;
exports.SettingGroup = SettingGroup;
const SettingLabel = _styledComponents.default.label`
  ${_blocks.typography.body}
  font-weight: ${_blocks.fontWeight.bold};
  display: flex;
  flex-direction: column;
  gap: ${_blocks.spacing.extraTight};
`;
exports.SettingLabel = SettingLabel;
const SettingDescription = _styledComponents.default.div``;
exports.SettingDescription = SettingDescription;